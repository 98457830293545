<template>
  <div class="pa-2">
    <CRow>
      <CCol sm="6" lg="4" class="form-inline">
        <label class="mr-2">
          {{$t('label.service')}}:
        </label>
        <v-select
          :filter="fuseSearchService"
          :options="serviceOptions"
          v-model="ServiceId"
          :placeholder="$t('label.select')"
          @input="act_serviceid"
          class="w-75"
        />
      </CCol>
      <CCol sm="6" lg="8" class="d-flex justify-content-end py-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalAsociarBuque"
        >
          <CIcon name="cil-playlist-add" /><span class="ml-1"
            >{{$t('label.affiliate')+$t('label.vessel')}}</span
          >
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedVesselServicesList"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      :loading="loadingTable"
      hover
      sorter
       
    >
      <template #loading>
        <loading/>
      </template>
      <template #Status="{ item }">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #options="{ item }">
        <td class="center-cell">
          <CButton
            color="edit"
            size="sm"
            v-c-tooltip="{
              content: $t('label.edit')+$t('label.affiliate')+$t('label.vessel'),
              placement: 'top',
            }"
            @click="toggleShowModalEditAsociarBuque(item)"
          >
            <CIcon name="pencil" />
          </CButton>
        </td>
      </template>
    </dataTableExtended>
    <modalBuque
      :modal.sync="showModal"
      @updated-modal="updateCLoseModal"
      :title="titleModal"
      :editModal="editModal"
      @refresh-data-table="mountedVesselServiceList"
      :serviceVesselSelected="serviceVesselSelected"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import modalBuque from './modal-buque';
import Fuse from "fuse.js";
import { mapState } from 'vuex';

function data() {
  return {
    itemsBuque: [],
    services: [],
    ServiceId: '',
    
    loadingTable: false,
    loadingOverlay: false,
    titleModal: '',
    showModal: false,
    editModal: false,
    serviceVesselSelected: {}
  };
}

function fields() {
  return  [
    { label: '#', key: 'Nro', filter:false, _style: 'width:1%; text-align:center;', _classes: 'text-center',},
    {
      label: this.$t('label.service'),
      key: 'ServiceCode',
      _classes: 'text-uppercase',
      _style: 'width:30%;'
    },
    {
      label: this.$t('label.vessel'),
      key: 'VesselName',
      _classes: 'text-uppercase',
      _style: 'width:20%;'
    },
    { label: this.$t('label.startDate'), key: 'startDate', _style: 'width:10%;' },
    { label: this.$t('label.endDate'), key: 'endDate', _style: 'width:10%;'},
    { label: this.$t('label.user'), key: 'TransaLogin', _classes: 'text-uppercase', _style: 'width:8%;' },
    { label: this.$t('label.date'), key: 'FormatedDate', _classes: 'cell-center text-center', _style: 'width:8%;' },
    { label: this.$t('label.status'), key: 'Status', _classes: 'cell-center text-center', _style: 'width:8%;' },
    { key: 'options', label: '', sorter: false, filter: false, _style: 'text-align:center; min-width:50px; width:5%;' },
  ];
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedVesselServiceList(id) {
  //this.loadingOverlay = true;
  this.itemsBuque = [];
  this.loadingTable = true;
  if (typeof id === 'undefined'){
    id='';
  } 
 if (this.ServiceId) id = this.ServiceId.value;
  
  this.$http
    .get('ServiceVessel-list?ServiceId='+id)
    .then((response) => (this.itemsBuque = response.data.data))
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}
//### Funcion computed para formatear los datos de la tabla ###
function computedVesselServicesList() {
  if (this.itemsBuque.length > 0) {
    return this.itemsBuque.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        startDate: item.StartDate
          ? DateFormater.formatOnlyDateWithSlash(item.StartDate)
          : 'N/A',
        endDate: item.EndDate ? DateFormater.formatOnlyDateWithSlash(item.EndDate) : 'N/A',
      };
    });
  }
}

function updateCLoseModal(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.serviceVesselSelected = {}
}

//## Activa el modal
function toggleShowModalAsociarBuque() {
  this.showModal = true;
  this.titleModal = this.$t('label.affiliate')+this.$t('label.vessel');
}

function toggleShowModalEditAsociarBuque(item) {
  this.serviceVesselSelected = item;
  this.showModal = true;
  this.titleModal = this.$t('label.edit')+this.$t('label.affiliate')+this.$t('label.vessel');
  this.editModal = true;
}

function listarServicios () {
  let listado = Array;
  this.$http.get('Service-list', { CompanyBranchId: this.user.BranchJson[0].CompanyBranchId, Filter: 'ACTIVO' })
  .then(response => {
    listado = [...response.data.data];
    this.services = listado.filter(ob => ob.Status==="ACTIVO" );
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

function fuseSearchService(options, search) {
  const fuse = new Fuse(options, {
    keys: ["ServiceCode"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function serviceOptions() {
  return this.services.map((ob) => Object.assign({}, ob, {
    label: ob.ServiceCode,
    value: ob.ServiceId
  }));
}

function act_serviceid(newService) {
  if (newService) {
    this.$store.commit('servicio/set', { value: newService.value, label: newService.label })
  } else {
    this.$store.commit('servicio/set', '');
  }  
}

export default {
  name: 'indexBuque',
  data,
  props: {
    tabValue: null,
  },
  mixins: [GeneralMixin],
  components: {
    modalBuque,
  },
  methods: {
    updateCLoseModal,
    toggleShowModalAsociarBuque,
    mountedVesselServiceList,
    toggleShowModalEditAsociarBuque,
    listarServicios,
    fuseSearchService,
    act_serviceid
  },
  mounted() {
    this.mountedVesselServiceList(),
    this.listarServicios()
  },
  watch: {
    ServicioId: function(newService) {
      if(newService){
        this.ServiceId = {
          value: newService.value,
          label: newService.label
        }
        this.mountedVesselServiceList(newService.value);
      } else {
        this.itemsBuque = [];
      }
    },
    ServiceId: function(nuevo) {
      if (nuevo == null) {
        this.mountedVesselServiceList();
      } else {
        this.mountedVesselServiceList(nuevo.value);
      }        
    },
    actList: function(nuevo) {
      this.listarServicios();
    },
    tabValue: async function (newValue, OldValue) {
      if(newValue == 2){
        this.mountedVesselServiceList(),
        this.listarServicios();
      }
    },
  },
  computed: {
    computedVesselServicesList,
    serviceOptions,
    fields,
    ...mapState({
        ServicioId: state => state.servicio.servicioId,
        actList: state => state.servicio.actList,
        user: state => state.auth.user,
    })
  }
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
