var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2"},[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: _vm.$t('label.service'),
          placement: 'top-end'
        }),expression:"{\n          content: $t('label.service'),\n          placement: 'top-end'\n        }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleShowModalCrearServicio}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedServicesList,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable,"hover":"","sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{style:(item.StatusColor)},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[(item.TpVesselId == '2A719797-70DB-437B-B55D-58F4177CB87B')?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.toView')+' '+_vm.$t('label.associatedLine'),
              placement: 'top',
            }),expression:"{\n              content: $t('label.toView')+' '+$t('label.associatedLine'),\n              placement: 'top',\n            }"}],attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalLineas(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1):_vm._e(),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.edit')+_vm.$t('label.service'),
              placement: 'top',
            }),expression:"{\n              content: $t('label.edit')+$t('label.service'),\n              placement: 'top',\n            }"}],staticClass:"ml-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalEditService(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])}),_c('modalServico',{attrs:{"modal":_vm.showModal,"title":_vm.titleModal,"editModal":_vm.editModal,"serviceSelected":_vm.serviceSelected},on:{"update:modal":function($event){_vm.showModal=$event},"updated-modal":_vm.updateCLoseModal,"refresh-data-table":_vm.mountedServiceList}}),_c('modalLineas',{attrs:{"modal":_vm.showModalLinea,"serviceSelected":_vm.serviceSelectedId},on:{"update:modal":function($event){_vm.showModalLinea=$event},"updated-modal-lineas":_vm.closeModalLineas}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }