<template>
  <main>
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      :closeOnBackdrop="false"
      size="xl"
      class="modal-content-vessel-buque"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />

      <CRow alignHorizontal="center">
        <CCol sm="5">
          <CRow>
            <CCol sm="12" class="justify-content-end mt-3">
              <CButton
                color="add"
                class="mt-1 float-right"
                @click="addBuque"
                v-c-tooltip="{
                  content: $t('label.vessel'),
                  placement: 'top-end',
                }"
              >
                <CIcon name="cil-playlist-add" />
                <span class="ml-1">
                  {{$t('label.nuevo')}}
                </span>
              </CButton>
            </CCol>
            <CCol sm="12" style="margin-top: 1rem">
              <dataTableExtended
                class="align-center-row-datatable data-table-vessel"
                size="lg"
                :fields="fields"
                :items="computedVesselList"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loadingTable"
                @row-clicked="selectVessel"
                hover
                sorter
              >
                <template #loading>
                  <loading/>
                </template>
                <template #icon="{ item }">
                  <td class="center-cell">
                    <c-img-extended
                      :src="item.icon"
                      :key="item.Nro"
                      :error-options="{ width: 40, height: 40, fontSize: 8 }"
                      block
                      thumbnail
                      class="img-logo"
                      :width="imageWidth"
                      :height="imageHeight"
                    />
                  </td>
                </template>
                
                  <template #options="{ item }">
                  <td class="center-cell">
                    <CButton
                      color="primary ml-2"
                      class="d-flex align-items-center"
                      v-c-tooltip="{
                        content: $t('label.edit')+$t('label.bulkCargo'),
                        placement: 'top',
                      }"
                    >
                      <CIcon name="cil-note" />
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="1">
          <CRow alignVertical="center" alignHorizontal="center">
            <CCol style="margin-top: 14rem">
              <CButton
                color="info"
                style="width: 4rem"
                @click="sendVesselToService"
                :disabled="computedBtnDisabled"
              >
                <CIcon name="cil-share" />
              </CButton>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="5">
          <CRow>
            <CCol sm="12" class="mt-3">
              <div class="form-group form-inline form-row">
                <label class="col-sm-3 text-right justify-content-end"><b class="text-danger">*</b> {{$t('label.service')}}</label>
                <v-select
                  :placeholder="$t('label.select')"
                  :options="computedServiceList"
                  class="select-services--vessel col-sm-9 pl-0 ml-0"
                  v-model="ServiceModel"
                  @input="selectServices"
                  v-model.trim="$v.ServiceModel.$model"
                  :class="
                    computedInputSelect
                      ? 'select-services--correct'
                      : ''
                  "
                />
              </div>
            </CCol>
            <CCol sm="6" v-if="false">
              <label class="mt-3"><b class="text-danger">*</b> STATUS</label>
              <CSelect
                v-model="Status"
                :value.sync="Status"
                :is-valid="statusSelectColor"
                :options="optionsStatus"
              />
            </CCol>
            <CCol sm="12" style="margin-top: 1rem;">
              <dataTableExtended
     class="align-center-row-datatable"
                size="lg"
                :fields="fields2"
                :items="computedSVesselServiceList"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loadingTable2"
                hover
                 
                sorter
              >
                <template #loading>
                  <loading/>
                </template>
                <template #icon="{ item }">
                  <td class="center-cell">
                    <c-img-extended
                      :src="item.icon"
                      :key="item.Nro"
                      :error-options="{ width: 40, height: 40, fontSize: 8 }"
                      block
                      thumbnail
                      class="img-logo"
                      :width="imageWidth"
                      :height="imageHeight"
                    />
                  </td>
                </template>
                <template #options="{ item }">
                  <td class="center-cell">
                    <CButton
                      size="sm"
                      color="wipe"
                      class="d-flex align-items-center"
                      v-c-tooltip="{
                        content: $t('label.delete')+$t('label.vessel'),
                        placement: 'top',
                      }"
                      @click="deleteVessel(item)"
                    >
                      <CIcon name="cil-trash" />
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          v-if="!editModal"
          outline
          color="add"
          :disabled="computedBtnEnabledSave"
          @click="saveVesselService"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton
          v-if="editModal"
          outline
          color="add"
          @click="submitUpdateVesselService"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import CImgExtended from '@/components/CImgExtended';
import CargaGranel from '@/_mixins/carga-granel';
import Buque from '@/_validations/servicio/buque';
import { mapState } from 'vuex';

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    loadingTable2: false,
    Status: 0,
    openDate: false,
    DriverId: '',  
    itemsBuques: [],
    itemsBuques2: [],
    imageWidth: 55,
    imageHeight: 45,
    ServiceId: '',
    ServiceModel: '',
    ServiceVesselArray: [],
    serviceList: [],
    vesselByService: [],
    vesselByServiceUpdate: [],
    btnDisabled: false,
    quantityVessel: 0,
    ServiceVesselId: '',
    TpVesselId: '',
  };
}

function optionsStatus(){
  return [
      { label: this.$t('label.ACTIVO'), value: 1 },
      { label: this.$t('label.INACTIVO'), value: 0 },
    ];
}

function fields(){
  return [
    { label: '#', key: 'Nro', filter: false, _style: 'width:3%; text-align:center;' },
    { label: this.$t('label.vessel'), key: 'VesselName', _classes: 'text-uppercase', _style: 'width:48%;' },
    {
      label: 'LLOYD',
      key: 'Imo',
      _style: 'width:20%;'
    },
    {
      label: this.$t('label.country'),
      key: 'icon',
      _style: 'width:15%; text-align:center;',
      _classes: 'text-center',
      filter: false,
    },
  ];
}

function fields2(){
  return [
      { label: '#', key: 'Nro', filter: false, _style: 'width:3%; text-align:center;' },
      { label: this.$t('label.vessel'), key: 'VesselName', _classes: 'text-uppercase', _style: 'width:45%;' },
      {
        label: 'LLOYD',
        key: 'imo',
        _classes: 'text-uppercase',
        _style: 'width:20%;'
      },
      {
        label: this.$t('label.country'),
        key: 'icon', _style: 'width:15%; text-align:center;', _classes: 'text-center', filter: false,
      },
      { key: 'options', label: '', sorter: false, filter: false, _style: 'min-width:50px; width:10%;' },
    ];
}
//######\
//Funcion para llamar a la ruta de agregar buque
//######
function addBuque() {
  this.closeModal();
  this.$router.push('buque/add');
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedVesselList(id) {
  //this.loadingOverlay = true;
  this.loadingTable = true;

  this.$http
    .get(`Vessel-by-service?ServiceId=${id}`)
    .then((response) => {
      if (response.data.data.length > 0) {
        this.itemsBuques = response.data.data.map((item) => {
          return {
            ...item,
            selected: false,
          };
        });
      } else {
        this.$notify({
          group: 'container',
          title: '¡Aviso!',
          text:
            'No hay buques disponibles con tipo de buque relacionado al servicio',
          type: 'info',
        });
      }
    })
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}
//### Funcion computed para formatear los datos de la tabla ###
function computedVesselList() {
  if (this.itemsBuques.length > 0) {
    return this.itemsBuques.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
        _classes: item.selected ? 'table-active' : '',
        icon:
          item.Icon !== ''
            ? `${this.$store.getters["connection/getBase"]}${item.Icon}`
            : `${this.$store.getters["connection/getBase"]}${item.Icon}`,
      };
    });
  }
}

function closeModal(event) {
  this.openDate = false;
  this.resetData();
  this.$emit('updated-modal', event);
  this.$emit('refresh-data-table');
  this.showModal = false;
}

//########
// Click row to select item
//########
async function selectVessel(payload) {
  if (this.ServiceId !== '') {
    const j = await this.arrayObjectIndexOf(
      this.ServiceVesselArray,
      payload.VesselId,
      'VesselId'
    );

    const i = await this.arrayObjectIndexOf(
      this.itemsBuques,
      payload.VesselId,
      'VesselId'
    );

    if (j !== -1) {
      await this.ServiceVesselArray.splice(j, 1);
      this.itemsBuques[i].selected = false;
    } else {
      this.itemsBuques[i].selected = true;
      this.ServiceVesselArray.push(payload);
    }
  }
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedServiceList() {
  //this.loadingOverlay = true;
  this.loadingOverlay = true;

  await this.$http
    .get('Service-list?CompanyBranchId='+this.user.BranchJson[0].CompanyBranchId+'&Filter=ACTIVO')
    .then((response) => {
      this.serviceList = response.data.data;
    })
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//########
// Data para las opciones del select
//########
function computedServiceList() {
  if (this.serviceList.length > 0) {
    return this.serviceList.map((item) => {
      return {
        label: item.ServiceCode,
        value: item.ServiceId,
        tpVessel: item.TpVesselId,
      };
    });
  }
}

//########
// Se activa al sleccionar un servicio del select
//########
async function selectServices(payload) {
  if (payload !== null) {
    if (payload.value!==null) {
      this.ServiceId = payload.value;
      this.ServiceModel = payload.label;
      this.TpVesselId = payload.tpVessel;
      await this.serviceSelected(this.ServiceId);
      await this.mountedVesselList(this.ServiceId);
    }    
  } else {
    this.ServiceId = '';
    this.ServiceModel = '';
    this.TpVesselId = '';
    this.ServiceVesselArray = [];
    this.vesselByService = [];
    this.itemsBuques = this.itemsBuques.map((item) => {
      return {
        ...item,
        selected: false,
      };
    });
  }
}

//########
// Se activa al sleccionar un servicio del select
//########
async function serviceSelected(id) {
  this.ServiceVesselArray = [];
  this.itemsBuques = await this.itemsBuques.map((item) => {
    return {
      ...item,
      selected: false,
    };
  });
  this.loadingTable2 = true;
  this.loadingTable = true;

  await this.$http
    .get(`ServiceVessel-list?ServiceId=${id}`)
    .then((response) => {
      if (!this.editModal) {
        this.quantityVessel = response.data.data.length;
        this.vesselByService = response.data.data;
      } else {
        let listado = response.data.data.filter((ob) => ob.Status=='ACTIVO');

        this.vesselByService = listado.map((element) => {
          if (element.ServiceVesselId === this.ServiceVesselId) {
            return {
              ...element,
              selected: true,
            };
          } else {
            return {
              ...element,
              selected: false,
            };
          }
        });
        
        listado.map((item) => {
          if (item.ServiceVesselId === this.ServiceVesselId) {
            this.vesselByServiceUpdate.push(item);
          }
        });
      }

      //this.ServiceVesselArray = this.vesselByService;
    })
    .finally(() => {
      this.loadingTable2 = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingTable2 = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//########
// retorna los datos de la tabal derecha
//########
function computedSVesselServiceList() {
  if (this.vesselByService.length > 0) {
    return this.vesselByService.map((item, index) => {
      return {
        ...item,
        index: 0,
        icon: item.Icon
          ? `${this.$store.getters["connection/getBase"]}${item.Icon}`
          : `${this.$store.getters["connection/getBase"]}${item.VesselFlagRoute}`,
        imo: item.Imo ? item.Imo : 'N/A',
        _classes: item.selected ? 'table-active' : '',
      };
    });
  }
}

//########
// Funcion para pasar los buques seleccionados de la tabla de la izquierda
//########
function sendVesselToService() {
  this.ServiceVesselArray.map(async (item) => {
    const j = await this.arrayObjectIndexOf(
      this.vesselByService,
      item.VesselId,
      'VesselId'
    );
    const i = await this.arrayObjectIndexOf(
      this.vesselByServiceUpdate,
      item.VesselId,
      'VesselId'
    );
    if (j === -1) {
      this.vesselByService.push(item);
    }
    if (this.editModal) {
      if (item.ServiceVesselId === undefined && i === -1) {
        this.vesselByServiceUpdate.push(item);
      }
    }
  });
}

//########
// Elimina un buque seleccionado
//########
async function deleteVessel(item) {
  /*if (item.ServiceVesselId !== undefined) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: 'Este buque ya pertenece a un servicio',
      type: 'error',
    });
  } else {*/
    this.$swal
    .fire({
     // html: '¿Estás seguro desea desactivar al Buque <b>'+item.VesselName+'</b>?',
      text: `${this.$t('label.deleteQuestion')} ${item.VesselName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel')
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (item.ServiceVesselId!==undefined) {
          this.loadingOverlay = true;
          const ServiceVesselJson = {
            ServiceVesselId: item.ServiceVesselId,
            ServiceId: item.ServiceId,
            VesselId: item.VesselId,
            VesselTeus: item.VesselTeus ? item.VesselTeus : item.Teus,
            Status: 0,
          };

          this.$http
          .post('ServiceVessel-insert', ServiceVesselJson, {
            root: 'ServiceVesselJson',
          })
          .then((response) => {
            if (response.status === (200 || 201)) {
              const messageSuccess = response.data.data[0].Response;
              this.loadingOverlay = false;
              this.$notify({
                group: 'container',
                title: '¡Solicitud Exitosa!',
                text: messageSuccess,
                type: 'success',
              });

              const i = this.arrayObjectIndexOf(
                this.vesselByService,
                item.VesselId,
                'VesselId'
              );

              this.vesselByService.splice(i, 1);

              const k = this.arrayObjectIndexOf(
                this.ServiceVesselArray,
                item.VesselId,
                'VesselId'
              );

              this.ServiceVesselArray.splice(k, 1);

              const j = this.arrayObjectIndexOf(
                this.vesselByServiceUpdate,
                item.VesselId,
                'VesselId'
              );

              for (let l = 0; l < this.itemsBuques.length; l++) {
                if (this.itemsBuques[l].VesselId === item.VesselId) {
                  this.itemsBuques[l].selected = false;
                }
              }

              this.vesselByServiceUpdate.splice(j, 1);
            }
          })
          .catch((e) => {
            this.loadingOverlay = false;
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: e,
              type: 'error',
            });
          });
        } else {
          const i = this.arrayObjectIndexOf(
            this.vesselByService,
            item.VesselId,
            'VesselId'
          );

          this.vesselByService.splice(i, 1);

          const k = this.arrayObjectIndexOf(
            this.ServiceVesselArray,
            item.VesselId,
            'VesselId'
          );

          this.ServiceVesselArray.splice(k, 1);

          const j = this.arrayObjectIndexOf(
            this.vesselByServiceUpdate,
            item.VesselId,
            'VesselId'
          );

          for (let l = 0; l < this.itemsBuques.length; l++) {
            if (this.itemsBuques[l].VesselId === item.VesselId) {
              this.itemsBuques[l].selected = false;
            }
          }

          this.vesselByServiceUpdate.splice(j, 1);
        }
      }
    });
  //}
}

//########
// EStatus enabled para el boton de agregar inten seleccionados
//########
function computedBtnDisabled() {
  if (this.ServiceId === '') {
    this.btnDisabled = true;
    return true;
  } else {
    this.btnDisabled = false;
    return false;
  }
}

//########
// EReinicia la data del modal
//########
function resetData() {
  this.vesselByService = [];
  this.vesselByServiceUpdate = [];
  this.ServiceId = '';
  this.ServiceModel = '';
  this.ServiceVesselArray = [];
  this.quantityVessel = 0;
  this.itemsBuques = [];
  this.vesselByService = [];
}

//########
// Construye la data para ser enviada en el servcio
//########
function generateServiceVesselJson(vesselJson) {
  if (!this.editModal) {
    return vesselJson.map((item) => {
      return {
        ServiceId: this.ServiceId,
        VesselId: item.VesselId,
        VesselTeus: item.VesselTeus ? item.VesselTeus : item.Teus,
      };
    });
  } else {
    return vesselJson.map((item) => {
      if (item.ServiceVesselId) {
        return {
          ServiceVesselId: this.ServiceVesselId,
          ServiceId: this.ServiceId,
          VesselId: item.VesselId,
          VesselTeus: item.VesselTeus ? item.VesselTeus : item.Teus,
          Status: this.Status,
        };
      } else {
        return {
          ServiceId: this.ServiceId,
          VesselId: item.VesselId,
          VesselTeus: item.VesselTeus ? item.VesselTeus : item.Teus,
        };
      }
    });
  }
}

//########
// Habilita el boton de guardar
//########
function computedBtnEnabledSave() {
  if (!this.editModal) {
    if (
      this.ServiceId === '' ||
      this.quantityVessel === this.vesselByService.length
    ) {
      return true;
    } else {
      return false;
    }
  }
}

//########
// Activa la clase valid o invalid del input select de servicio
//########
function computedInputSelect() {
  if (this.ServiceId === '') {
    return false;
  } else {
    return true;
  }
}

//########
// Funcion para el POST del modulo
//########
async function saveVesselService() {
  try {
    this.loadingOverlay = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      throw 'Existen errores, por favor, verifique.';
    }

    if (this.vesselByService.length==0){
      this.loadingOverlay = false;
      throw 'Ningun buque seleccionado';
    }
    const vesselJson = await this.generateServiceVesselJson(
      this.vesselByService
    );
    const ServiceVesselJson = vesselJson;

    await this.$http
      .post('ServiceVessel-insert', ServiceVesselJson, {
        root: 'ServiceVesselJson',
      })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-table');
          this.loadingOverlay = false;
          this.closeModal(false);
          this.resetData();
          this.$notify({
            group: 'container',
            title: '¡Solicitud Exitosa!',
            text: messageSuccess,
            type: 'success',
          });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
  } catch (e) {
    this.loadingOverlay = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

//########
// Cambia el status del servicio
//########
function selectStatus(event) {
  this.Status = event.target.value;
}

//########
// funcion para llamar al update del servcio
//########
async function submitUpdateVesselService() {
  try {
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      throw 'Existen errores, por favor, verifique.';
    }

    this.checkSTatusServiceVessel();
  } catch (e) {
    this.loadingOverlay = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

//########
// Verifica el status a la hora de realizar el update
//########
async function checkSTatusServiceVessel() {
  if (this.vesselByService.length==0){
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: 'Ningun buque seleccionado',
      type: 'error',
    });
    return false;
  }

  this.updateServiceVessel();
  /*try {
    if (this.Status == 0) {
      this.$swal
        .fire({
          title: '¿Estás seguro desea desactivar a este Servicio asociado?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updateServiceVessel();
          }
        });
    } else {
      this.updateServiceVessel();
    }
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }*/
}

//########
// llama al metodo put
//########
async function updateServiceVessel() {
  this.loadingOverlay = true;
  const vesselJson = await this.generateServiceVesselJson(
    this.vesselByServiceUpdate
  );
  const ServiceVesselJson = vesselJson;

  await this.$http
    .post('ServiceVessel-insert', ServiceVesselJson, {
      root: 'ServiceVesselJson',
    })
    .then((response) => {
      if (response.status === (200 || 201)) {
        const messageSuccess = response.data.data[0].Response;
        this.$emit('refresh-data-table');
        this.loadingOverlay = false;
        this.resetData();
        this.closeModal(false);
        this.$notify({
          group: 'container',
          title: '¡Solicitud Exitosa!',
          text: messageSuccess,
          type: 'success',
        });
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//### Funcion mounted para cargar los datos de la tabla ###
async function VesselById(id) {
  //this.loadingOverlay = true;
  this.loadingOverlay = true;

  await this.$http
    .get(`Vessel-by-id?VesselId=${id}`)
    .then((response) => {
      this.TpVesselId = response.data.data[0].TpVesselId;
    })
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

export default {
  name: 'modal-buque',
  data,
  props: {
    title: String,
    modal: null,
    editModal: Boolean,
    serviceVesselSelected: Object,
  },
  mixins: [GeneralMixin, CargaGranel],
  directives: UpperCase,
  validations: Buque,
  watch: {
    modal: async function(val) {
      this.showModal = val;
      this.resetData();
      if (val === true) {
        await this.mountedServiceList();
        if (this.ServicioId!="") {
          this.ServiceId = this.ServicioId.value;
          this.ServiceModel = this.ServicioId.label;
          let arr = this.serviceList.filter(x => x.ServiceId == this.ServiceId);
          if (arr.length > 0) {
            let arreglo = [];
            arreglo.push({label: arr[0].ServiceCode, value: arr[0].ServiceId, tpVessel: arr[0].TpVesselId});
            this.selectServices(arreglo[0]);
          }
        }
      }
    },
    serviceVesselSelected: async function(val) {
      if (Object.keys(val).length !== 0) {
        this.ServiceId = val.ServiceId;
        this.ServiceVesselId = val.ServiceVesselId;
        this.Status = val.Status === 'ACTIVO' ? 1 : 0;
        this.ServiceModel = val.ServiceCode;
        await this.VesselById(val.VesselId);
        await this.mountedVesselList(this.TpVesselId);
        await this.serviceSelected(this.ServiceId);
      }
    },
  },
  methods: {
    closeModal,
    mountedVesselList,
    selectVessel,
    mountedServiceList,
    serviceSelected,
    selectServices,
    sendVesselToService,
    deleteVessel,
    resetData,
    generateServiceVesselJson,
    saveVesselService,
    selectStatus,
    submitUpdateVesselService,
    checkSTatusServiceVessel,
    updateServiceVessel,
    addBuque,
    VesselById,
  },
  computed: {
    computedVesselList,
    computedServiceList,
    computedSVesselServiceList,
    computedBtnDisabled,
    computedBtnEnabledSave,
    computedInputSelect,
    fields,
    fields2,
    optionsStatus,
    ...mapState({
        ServicioId: state => state.servicio.servicioId,
        user: state => state.auth.user,
    })
  },
  components: {
    CImgExtended,
  },
};
</script>
<style lang="scss">
.text-area-descripcion-servicio {
  textarea {
    resize: none !important;
  }
}
.modal-content-vessel-buque {
  .modal-content {
    width: 100% !important;
    margin: auto !important;
   // margin-left: -10% !important;
  }
}
.select-services--vessel .vs__dropdown-menu {
  max-height: 280px !important;
}
.select-services--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
}
.select-services--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  }
}
.data-table-vessel {
  .table-responsive {
    tbody {
      tr {
        cursor: pointer !important;
      }
    }
    table {
      td {
        vertical-align: middle !important;
      }
    }
  }

}

.center-cell {
  text-align: center;
}
.table-index {
  table {
    td {
      vertical-align: middle !important;
    }
  }
  .table-responsive {
    /*display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;*/
   overflow-x: hidden;
    
  }
}



</style>



