<template>
  <div class="pa-2">
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end py-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="toggleShowModalCrearServicio"
           v-c-tooltip="{
            content: $t('label.service'),
            placement: 'top-end'
          }"
        >
          <CIcon name="cil-playlist-add" /><span class="ml-1"
            >{{$t('label.nuevo')}}</span
          >
        </CButton>
      </CCol>
    </CRow>
    <dataTableExtended
     class="align-center-row-datatable"
      size="lg"
      :fields="fields"
      :items="computedServicesList"
      :items-per-page="5"
      column-filter
      pagination
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      :loading="loadingTable"
      hover
      sorter
       
    >
      <template #loading>
        <loading/>
      </template>
      <template #Status="{ item }">
        <td class="center-cell">
          <!--CBadge :color="getBadgeServiceStatus(item.servicesStatus)">
            {{ $t('label.'+item.servicesStatus) }}
          </CBadge-->
          <CBadge :style="item.StatusColor">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>

      <template #options="{ item }">
        <td class="text-center">
            <CButton
              color="watch"
              size="sm"
              v-c-tooltip="{
                content: $t('label.toView')+' '+$t('label.associatedLine'),
                placement: 'top',
              }"
              @click="toggleShowModalLineas(item)"
              v-if="item.TpVesselId == '2A719797-70DB-437B-B55D-58F4177CB87B'"
            >
              <CIcon name="eye" />
            </CButton>
            <CButton
              color="edit"
              size="sm"
              class="ml-1"
              v-c-tooltip="{
                content: $t('label.edit')+$t('label.service'),
                placement: 'top',
              }"
              @click="toggleShowModalEditService(item)"
            >
              <CIcon name="pencil" />
            </CButton>
        </td>
      </template>
    </dataTableExtended>
    <modalServico
      :modal.sync="showModal"
      @updated-modal="updateCLoseModal"
      :title="titleModal"
      :editModal="editModal"
      @refresh-data-table="mountedServiceList"
      :serviceSelected="serviceSelected"
    />

    <modalLineas
      :modal.sync="showModalLinea"
      @updated-modal-lineas="closeModalLineas"
      :serviceSelected="serviceSelectedId"
    />
  </div>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import modalServico from './modal-servicio';
import modalLineas from './modal-lineas';
import { mapState } from 'vuex';

function data() {
  return {
    itemsServicio: [],
    statusSelectOptions: [],
    statusSelectOptionsTemp: [],
    loadingTable: false,
    loadingOverlay: false,
    titleModal: '',
    showModal: false,
    editModal: false,
    serviceSelected: {},
    serviceSelectedId: '',
    showModalLinea: false,
  };
}

function toggleShowModalEditService(item) {
  this.serviceSelected = item;
  this.showModal = true;
  this.titleModal = this.$t('label.edit')+this.$t('label.service')+': '+this.serviceSelected.ServiceCode;
  this.editModal = true;
}

function toggleShowModalLineas(item) {
  this.showModalLinea = true;
  this.serviceSelectedId = item.ServiceId;
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedServiceList() {
  //this.loadingOverlay = true;
  this.loadingTable = true;
  await this.mounteStatusList();

  this.$http
    .get('Service-list?CompanyBranchId='+this.user.BranchJson[0].CompanyBranchId)
    .then((response) => (this.itemsServicio = response.data.data))
    .finally(() => {
      this.loadingOverlay = false;
      this.loadingTable = false;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

// computed 
function fields(){
  return [
      { label: '#', key: 'Nro', filter:false, _style: 'width:1%; text-align:center;', _classes: 'text-center', },
      {
        label: this.$t('label.serviceCode'),
        key: 'ServiceCode',
        _classes: 'text-uppercase',
        _style: 'width:12%',
      },
      {
        label: this.$t('label.vesselType'),
        key: 'TpVesselName',
        _classes: 'text-uppercase',
        _style: 'width:12%',
      },
      { label: this.$t('label.startDate'), key: 'startDate', _style: 'width:8%;' },
      { label: this.$t('label.endDate'), key: 'endDate', _style: 'width:8%;' },
      { label: this.$t('label.user'), key: 'TransaLogin',  _classes: 'text-uppercase', _style: 'width:8%;' },
      { label: this.$t('label.date'), key: 'FormatedDate', _classes:'cell-center text-center', _style: 'width:8%;'  },
      { label: this.$t('label.status'), key: 'Status', _classes:'cell-center text-center', _style: 'width:8%;' },
      { key: 'options', label: '', sorter: false, filter: false, _style: 'min-width: 100px; width:1%;' },
    ];
}
//### Funcion computed para formatear los datos de la tabla ###
function computedServicesList() {
  if (this.itemsServicio.length > 0) {
    return this.itemsServicio.map((item) => {
      return {
        ...item,
        FormatedDate: item.TransaRegDate
          ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
          : 'N/A',
       // _classes: this.SetClassTable(item.ServiceStatus, 'class'),
        startDate: item.StartDate
          ? DateFormater.formatOnlyDateWithSlash(item.StartDate)
          : 'N/A',
        endDate: item.EndDate ? DateFormater.formatOnlyDateWithSlash(item.EndDate) : 'N/A',
       // servicesStatus: this.SetClassTable(item.ServiceStatus, 'badge'),
        Status: item.Status ? item.Status : '',
        StatusColor: item.StatusColor ? "color:"+item.StatusColor : '',
      };
    });
  }
}

async function mounteStatusList() {
  this.loadingOverlay = true;
  await this.$http
    .get('ServiceStatus-list')
    .then((response) => {
      this.loadingOverlay = false;
      this.statusSelectOptions = response.data.data;
      this.statusSelectOptionsTemp = this.statusSelectOptions;
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function SetClassTable(statusServices, value) {
  const status = this.statusSelectOptions.filter(
    (element) => element.ProcessStatusId === statusServices
  );
  if (status.length > 0 && value === 'class') {
    if (status[0].ValueDs === 'ACTIVO') {
      return '';
    } else if (status[0].ValueDs === 'INACTIVO') {
      return 'table-danger';
    } else {
      return 'table-danger';
    }
  } else {
    return status[0].ValueDs;
  }
}

function updateCLoseModal(event) {
  this.showModal = false;
  this.titleModal = '';
  this.editModal = false;
  this.serviceSelected = {};
}

function closeModalLineas() {
  this.showModalLinea = false;
  this.serviceSelectedId = '';
}

//## Activa el modal
function toggleShowModalCrearServicio() {
  this.showModal = true;
  this.titleModal =this.$t('label.nuevo')+this.$t('label.service');
}

export default {
  name: 'indexServicio',
  data,
  props: {
    tabValue: null,
  },
  mixins: [GeneralMixin],
  components: {
    modalServico,
    modalLineas,
  },
  methods: {
    updateCLoseModal,
    toggleShowModalCrearServicio,
    mountedServiceList,
    toggleShowModalEditService,
    SetClassTable,
    mounteStatusList,
    toggleShowModalLineas,
    closeModalLineas
  },
  mounted: mountedServiceList,
  computed: {
    computedServicesList,
    fields,
    ...mapState({
        user: state => state.auth.user,
    })
  },
  watch: {
    tabValue: async function (newValue, OldValue) {
      if(newValue == 0){
        this.mountedServiceList();
      }
    },
  },
};
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>
