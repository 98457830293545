var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('CModalExtended',{staticClass:"modal-content-vessel-buque",attrs:{"title":_vm.title,"color":"dark","show":_vm.showModal,"closeOnBackdrop":false,"size":"xl"},on:{"update:show":[function($event){_vm.showModal=$event},function($event){return _vm.closeModal($event)}]},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(!_vm.editModal)?_c('CButton',{attrs:{"outline":"","color":"add","disabled":_vm.computedBtnEnabledSave},on:{"click":_vm.saveVesselService}},[_c('CIcon',{attrs:{"name":"checkAlt"}}),_vm._v("  "+_vm._s(_vm.$t('button.accept'))+" ")],1):_vm._e(),(_vm.editModal)?_c('CButton',{attrs:{"outline":"","color":"add"},on:{"click":_vm.submitUpdateVesselService}},[_c('CIcon',{attrs:{"name":"checkAlt"}}),_vm._v("  "+_vm._s(_vm.$t('button.accept'))+" ")],1):_vm._e(),_c('CButton',{attrs:{"color":"wipe"},on:{"click":function($event){return _vm.closeModal(false)}}},[_c('CIcon',{attrs:{"name":"x"}}),_vm._v("  "+_vm._s(_vm.$t('button.cancel'))+" ")],1)]},proxy:true}])},[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('CRow',{attrs:{"alignHorizontal":"center"}},[_c('CCol',{attrs:{"sm":"5"}},[_c('CRow',[_c('CCol',{staticClass:"justify-content-end mt-3",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.vessel'),
                placement: 'top-end',
              }),expression:"{\n                content: $t('label.vessel'),\n                placement: 'top-end',\n              }"}],staticClass:"mt-1 float-right",attrs:{"color":"add"},on:{"click":_vm.addBuque}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('label.nuevo'))+" ")])],1)],1),_c('CCol',{staticStyle:{"margin-top":"1rem"},attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable data-table-vessel",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedVesselList,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable,"hover":"","sorter":""},on:{"row-clicked":_vm.selectVessel},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"icon",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('c-img-extended',{key:item.Nro,staticClass:"img-logo",attrs:{"src":item.icon,"error-options":{ width: 40, height: 40, fontSize: 8 },"block":"","thumbnail":"","width":_vm.imageWidth,"height":_vm.imageHeight}})],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.edit')+_vm.$t('label.bulkCargo'),
                      placement: 'top',
                    }),expression:"{\n                      content: $t('label.edit')+$t('label.bulkCargo'),\n                      placement: 'top',\n                    }"}],staticClass:"d-flex align-items-center",attrs:{"color":"primary ml-2"}},[_c('CIcon',{attrs:{"name":"cil-note"}})],1)],1)]}}])})],1)],1)],1),_c('CCol',{attrs:{"sm":"1"}},[_c('CRow',{attrs:{"alignVertical":"center","alignHorizontal":"center"}},[_c('CCol',{staticStyle:{"margin-top":"14rem"}},[_c('CButton',{staticStyle:{"width":"4rem"},attrs:{"color":"info","disabled":_vm.computedBtnDisabled},on:{"click":_vm.sendVesselToService}},[_c('CIcon',{attrs:{"name":"cil-share"}})],1)],1)],1)],1),_c('CCol',{attrs:{"sm":"5"}},[_c('CRow',[_c('CCol',{staticClass:"mt-3",attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group form-inline form-row"},[_c('label',{staticClass:"col-sm-3 text-right justify-content-end"},[_c('b',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('label.service')))]),_c('v-select',{staticClass:"select-services--vessel col-sm-9 pl-0 ml-0",class:_vm.computedInputSelect
                    ? 'select-services--correct'
                    : '',attrs:{"placeholder":_vm.$t('label.select'),"options":_vm.computedServiceList},on:{"input":_vm.selectServices},model:{value:(_vm.$v.ServiceModel.$model),callback:function ($$v) {_vm.$set(_vm.$v.ServiceModel, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.ServiceModel.$model"}})],1)]),(false)?_c('CCol',{attrs:{"sm":"6"}},[_c('label',{staticClass:"mt-3"},[_c('b',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" STATUS")]),_c('CSelect',{attrs:{"value":_vm.Status,"is-valid":_vm.statusSelectColor,"options":_vm.optionsStatus},on:{"update:value":function($event){_vm.Status=$event}},model:{value:(_vm.Status),callback:function ($$v) {_vm.Status=$$v},expression:"Status"}})],1):_vm._e(),_c('CCol',{staticStyle:{"margin-top":"1rem"},attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields2,"items":_vm.computedSVesselServiceList,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable2,"hover":"","sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"icon",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('c-img-extended',{key:item.Nro,staticClass:"img-logo",attrs:{"src":item.icon,"error-options":{ width: 40, height: 40, fontSize: 8 },"block":"","thumbnail":"","width":_vm.imageWidth,"height":_vm.imageHeight}})],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.delete')+_vm.$t('label.vessel'),
                      placement: 'top',
                    }),expression:"{\n                      content: $t('label.delete')+$t('label.vessel'),\n                      placement: 'top',\n                    }"}],staticClass:"d-flex align-items-center",attrs:{"size":"sm","color":"wipe"},on:{"click":function($event){return _vm.deleteVessel(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }